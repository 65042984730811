// @flow

// The environment the app is running on. Locally this is: REACT_APP_ENV
export const appEnvironment = 'production';

export const API_URL = 'https://ms-api-dot-prd-minchem-solvextract.appspot.com';
export const API_VER = '/api/';

export const firebaseConfig = {
    apiKey: 'AIzaSyD0GNeITYl3eSxq87_zS_8inSspuO131lU',
    authDomain: 'prd-minchem-solvextract.firebaseapp.com',
    projectId: 'prd-minchem-solvextract',
};

export const gaTrackingId = 'UA-115813374-6';

// Used to replace Login UI, for application blocking (must contain content to block application)
export const appBlockingMessage = '';

export const NAVBAR_DASHBOARD_V2_REDIRECT = true;

/**
 * A list of users allowed to use MinChem V2
 * The list should be an array of emails.
 */
export const MINCHEM_V2_TEST_USER_EMAILS = [
    "dverdi1@fmi.com"
];

export const PIWIK_PRO_TRACKERID = '37978e9c-5807-4ca6-9d50-2b1a3819bd7a';
export const PIWIK_PRO_API_URL = 'https://solvay.containers.piwik.pro';